import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Navbar from '../navbar'
import './layout.scss'

import $ from 'jquery';

// Add icons we use elsewhere here for treeshaking and to import the SVG for use in the icon component
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faBars, faChevronDown, faSpinner, faMapPin, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
library.add(faTimes, faBars, faChevronDown, faSpinner, faMapPin, faPhone, faEnvelope);


global.$ = $;
try { eval('window.jQuery = $;') } catch(e) {}

class Layout extends React.Component {
	render() { return (
		<StaticQuery
			query={graphql`
				query SiteTitleQuery {
					site {
						siteMetadata {
							title
						}
					}
				}
			`}
			render={data => (
				<>
					<Helmet
						title={data.site.siteMetadata.title}
						meta={[
							{ name: 'description', content: 'Pleasant Hill Church, Union City, IN' },
							{ name: 'keywords', content: 'PHC, Church, Worship' },
						]}
					>
						<html lang="en" />
					</Helmet>
					<Navbar siteTitle={data.site.siteMetadata.title} page={this.props.page}/>
					<div className="page-wrap" id="layout-page-root">
						{this.props.children}
					</div>
				</>
			)}
		/>
	)}
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout




// <div
//   style={{
//     margin: '0 auto',
//     maxWidth: 960,
//     padding: '0px 1.0875rem 1.45rem',
//     paddingTop: 0,
//   }}
// >
