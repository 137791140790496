import React from 'react';
import Img from "gatsby-image";
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'

export default function SocialIcons(props) {
	return (
		<StaticQuery
			query={graphql`
				query {
					file(relativePath: { regex: "/phc-logo-white.png/" }) {
						childImageSharp {
							fixed(width: 240, height: 60) {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
			`}
			render={data => (
				<Link to="/" className={props.className || "logo-image"}>
					<Img fixed={data.file.childImageSharp.fixed}/>
				</Link>
			)}
		/>
	)
}
